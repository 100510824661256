import { CardTable, StyledCardContainer } from "components/Card/Card.styled"
import { Box } from "components/Shared/Box.styled"
import { FlexRow } from "components/Shared/FlexRow"
import Row from "components/Table/Rows/Row"
import { StyledDateTimeRow } from "components/Table/Rows/Row.styled"
import {
  StyledColumn,
  StyledTableRow,
  TableContainer,
  TableHead,
  TableStyled,
} from "components/Table/Table.styled"
import { Gateway } from "components/types/types"
import { mapServiceName } from "helpers/helpers"
import moment from "moment"
import React from "react"
import { semibold, xsmall } from "styles/fonts"

interface Props {
  notifications: Gateway["alerts"]
  offline?: boolean
}

const Notifications = ({ notifications, offline }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const clickHandler = (comp: string) => {}

  return (
    <>
      <CardTable>
        <StyledCardContainer offline={offline}>
          <TableContainer>
            <div
              style={{
                width: "100%",
              }}
            >
              <Box
                direction="row"
                align="center"
                padding="0 10px 10px 0 "
                justify="space-between"
              >
                <p
                  style={{
                    fontSize: xsmall,
                    fontWeight: semibold,
                  }}
                >
                  {`Last Notifications ( ${notifications?.length} )`}
                </p>
                {/* <StyledCheckRow>
                  <CheckboxField
                    label={"Load Manager"}
                    onClick={() => clickHandler("LMD")}
                    checked={true}
                    width={0}
                  />
                </StyledCheckRow> */}
              </Box>
            </div>
            <TableStyled drag={false} padding={false}>
              <thead>
                <TableHead>
                  <StyledColumn width={100} ref={null}>
                    <FlexRow style={{ justifyContent: "space-between" }}>
                      <FlexRow style={{ alignItems: "left" }}>
                        <p>When</p>
                      </FlexRow>
                    </FlexRow>
                  </StyledColumn>
                  <StyledColumn width={100} ref={null}>
                    <FlexRow style={{ justifyContent: "space-between" }}>
                      <FlexRow style={{ alignItems: "left" }}>
                        <p>Time</p>
                      </FlexRow>
                    </FlexRow>
                  </StyledColumn>
                  <StyledColumn width={100} ref={null}>
                    <FlexRow style={{ justifyContent: "space-between" }}>
                      <FlexRow style={{ alignItems: "left" }}>
                        <p>Component</p>
                      </FlexRow>
                    </FlexRow>
                  </StyledColumn>
                  <StyledColumn width={100} ref={null}>
                    <FlexRow style={{ justifyContent: "space-between" }}>
                      <FlexRow style={{ alignItems: "left" }}>
                        <p>Message</p>
                      </FlexRow>
                    </FlexRow>
                  </StyledColumn>
                </TableHead>
              </thead>
              <tbody>
                {notifications?.map((entry, i) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <StyledTableRow disabled={false}>
                      <Row
                        text={moment(notifications[i].timestamp).fromNow()}
                        width={100}
                      />
                      <StyledDateTimeRow>
                        <p>
                          {moment(notifications[i].timestamp).format(
                            "HH:mm:ss"
                          )}
                        </p>
                        <p>
                          {moment(notifications[i].timestamp).format(
                            "DD-MM-YYYY"
                          )}
                        </p>
                      </StyledDateTimeRow>
                      <Row
                        text={
                          notifications[i].service
                            ? mapServiceName(notifications[i].service)
                            : "LMD"
                        }
                        width={10}
                      />
                      <Row text={notifications[i].message} width={10} />
                    </StyledTableRow>
                  )
                })}
              </tbody>
            </TableStyled>
          </TableContainer>
        </StyledCardContainer>
      </CardTable>
    </>
  )
}

export default Notifications
