import axios from "axios"
import Button from "components/Buttons/Button"
import Logo from "components/Icons/Logo"
import Loader from "components/Loader/Loader"
import { Box } from "components/Shared/Box.styled"
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react"
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce"
import { regular } from "styles/fonts"

export type UserInfo = {
  email: string
  groups: { id: string; role: string }[]
  cognitoUser: string
  approved: boolean
  mfaSetup: boolean
  expertSettingsEnabled: boolean
}

interface UserContext {
  userInfo: UserInfo
  updateUserInfo: (expertSettingsEnabled: boolean) => void
}

export const Context = createContext<UserContext>({
  userInfo: {} as UserInfo,
  updateUserInfo: () => null,
})

export const UserContextProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState(true)
  const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo)
  const { token, logOut } = useContext<IAuthContext>(AuthContext)
  const getUserInfo = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/userInfo`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setUserInfo(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const updateUserInfo = async (expertSettingsEnabled: boolean) => {
    setLoading(true)
    await axios
      .put(
        `${process.env.REACT_APP_API_ADDRESS}/userInfo`,
        {
          expertSettingsEnabled,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setUserInfo((prev) => ({ ...prev, ...response.data }))
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getUserInfo()
  }, [token])
  return !loading ? (
    userInfo.approved ? (
      <Context.Provider
        value={{
          userInfo,
          updateUserInfo,
        }}
      >
        {children}
      </Context.Provider>
    ) : (
      <Box
        direction="column"
        justify="center"
        align="center"
        padding="50px 50px 50px 50px"
      >
        <Logo />
        <p style={{ fontSize: regular }}>
          {" "}
          Contact your group administrator or Floading support to grant you
          access to the platform.
        </p>
        <Button
          text="Logout"
          onClick={() => {
            logOut()
          }}
        />
      </Box>
    )
  ) : (
    <Loader />
  )
}

export const useUserContext = () => useContext<UserContext>(Context)
