/* eslint-disable indent */
import { TableEntryType } from "components/types/types"
import { currentDecimals, currentToPowerNoneMultiply } from "helpers/helpers"
import React from "react"

import { StyledRow } from "./Row.styled"

interface Props extends TableEntryType {
  l1?: number
  l2?: number
  l3?: number
  unit?: string
  power?: number
  soc?: number
  calculate?: boolean
}

const calculateTotal = (calculate: boolean | undefined, total: number) => {
  return calculate ? currentToPowerNoneMultiply(total) : currentDecimals(total)
}
const TripleRow = ({ l1, l2, l3, unit, power, calculate, soc }: Props) => {
  return (
    <StyledRow>
      <p>
        {l1 && l2 && l3 ? currentDecimals(l1) : ""}
        {l1 && l2 && l3 ? unit + " | " : ""}
        {l1 && l2 && l3 ? currentDecimals(l2) : ""}
        {l1 && l2 && l3 ? unit + " | " : ""}
        {l1 && l2 && l3 ? currentDecimals(l3) : ""}
        {l1 && l2 && l3 ? unit + " | " : ""}
        {power
          ? currentDecimals(power / 1000) + " kW"
          : (l1 === undefined && l2 === undefined && l3 === undefined
              ? "-"
              : currentToPowerNoneMultiply(
                  Number(l1) + Number(l2) + Number(l3)
                )) + " kW"}
        {soc ? " | SoC: " + soc + " %" : ""}
      </p>
    </StyledRow>
  )
}

export default TripleRow
