import axios from "axios"
import Button from "components/Buttons/Button"
import InputField from "components/InputField/InputField"
import SelectField from "components/InputField/SelectField"
import Loader from "components/Loader/Loader"
import { Box } from "components/Shared/Box.styled"
import { FlexCol } from "components/Shared/FlexCol"
import { Label } from "components/Shared/Label.styled"
import { SubPaths } from "components/Shared/SubPaths"
import { Warning } from "components/Shared/Warning"
import SubMenu from "components/SubMenu/SubMenu"
import { Gateway } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  id: string
}

const General = ({ id }: Props) => {
  const [loading, setLoading] = useState(true)
  const [changed, setChanged] = useState(false)
  const [data, setData] = useState<Gateway>()
  const { toasts, setToasts, token } = useAppContext()
  const unbalancedLoadPrevention = useRef<HTMLSelectElement | null>(null)
  const maximumPhase = useRef<HTMLInputElement | null>(null)

  const adaptiveCharging = useRef<HTMLSelectElement | null>(null)
  const adaptiveDuration = useRef<HTMLInputElement | null>(null)
  const adaptivePeriod = useRef<HTMLInputElement | null>(null)

  const gateway = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/gateways/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const updateGateway = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_ADDRESS}/gateways/${id}`,
        {
          unbalancedLoadPrevention: {
            enabled:
              unbalancedLoadPrevention.current?.value === t("Enabled")
                ? true
                : false,
            maximumPhaseCurrentDifference: maximumPhase.current?.value,
          },
          adaptiveCharging: {
            enabled:
              adaptiveCharging.current?.value === t("Enabled") ? true : false,
            reofferPeriod: adaptivePeriod.current?.value,
            reofferDuration: adaptiveDuration.current?.value,
          },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
        setChanged(false)
      })
      .catch((err) => {
        console.log(err)
        toastHandler(toasts, setToasts, false)
        setChanged(false)
      })
  }

  useEffect(() => {
    gateway()
    const interval = setInterval(() => {
      gateway()
    }, 5000)
    return () => clearInterval(interval)
  }, [changed, token])

  const { t } = useTranslation()

  return (
    <FlexCol>
      <SubMenu items={SubPaths()} id={id} />
      {!loading ? (
        <div>
          <Box padding="14px 0 0 0">
            <Label blue={true} size="regular">
              {t("Unbalanced Load Preventation")}
            </Label>
          </Box>
          <Box padding="14px 0 0 0">
            <SelectField
              label={t("Unbalanced Load Preventation")}
              options={[t("Enabled"), t("Disabled")]}
              onChange={() => setChanged(true)}
              selected={
                data?.unbalancedLoadPrevention?.enabled
                  ? t("Enabled")
                  : t("Disabled")
              }
              innerRef={unbalancedLoadPrevention}
              width={50}
            />
          </Box>
          <Box padding="14px 0 0 0" width={50}>
            <InputField
              width={100}
              type="number"
              onChange={() => setChanged(true)}
              label={t("Maximum Phase Current Difference (A)")}
              value={
                data?.unbalancedLoadPrevention?.maximumPhaseCurrentDifference
              }
              innerRef={maximumPhase}
              min={0}
            />
          </Box>
          <Box padding="28px 0 0 0">
            <Label blue={true} size="regular">
              {t("Adaptive Charging")}
            </Label>
          </Box>
          <Box padding="14px 0 0 0">
            <SelectField
              label={t("Adaptive Charging")}
              options={[t("Enabled"), t("Disabled")]}
              onChange={() => setChanged(true)}
              selected={
                data?.adaptiveCharging?.enabled ? t("Enabled") : t("Disabled")
              }
              innerRef={adaptiveCharging}
              width={50}
            />
          </Box>
          <Box padding="14px 0 0 0" width={50}>
            <InputField
              width={100}
              type="number"
              onChange={() => setChanged(true)}
              label={t("Adaptive Charging Reoffer Period (s)")}
              value={data?.adaptiveCharging?.reofferPeriod}
              innerRef={adaptivePeriod}
              min={0}
            />
          </Box>
          <Box padding="14px 0 0 0" width={50}>
            <InputField
              width={100}
              type="number"
              onChange={() => setChanged(true)}
              label={t("Adaptive Charging Reoffer Duration (s)")}
              value={data?.adaptiveCharging?.reofferDuration}
              innerRef={adaptiveDuration}
              min={0}
            />
          </Box>
          {changed && (
            <Box padding="20px 0 0 0" width={50} justify="space-between">
              {changed && <Warning>{t("Unsaved changes.")}</Warning>}
              <Button
                margin="0 0 0 20px"
                onClick={() => updateGateway()}
                text={t("Save Changes")}
              />
            </Box>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </FlexCol>
  )
}

export default General
