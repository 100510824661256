import axios from "axios"
import Button from "components/Buttons/Button"
import ButtonIcon from "components/Buttons/ButtonIcon"
import { Eye } from "components/Icons/Eye"
import { EyeSlash } from "components/Icons/EyeSlash"
import CheckboxField from "components/InputField/CheckboxField"
import InputField from "components/InputField/InputField"
import SelectField from "components/InputField/SelectField"
import Loader from "components/Loader/Loader"
import { Box } from "components/Shared/Box.styled"
import { Label } from "components/Shared/Label.styled"
import { BackendsArrayType, Chargepoint } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import {
  currentToPower,
  getIdOfBackendFromName,
  priorityToNumber,
  toastHandler,
} from "helpers/helpers"
import { usePopUpContext } from "helpers/PopupProvider"
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"

interface Props {
  entry: Chargepoint
  setChanged: Dispatch<SetStateAction<boolean>>
}
const ChargingStationConfigurationPopUp = ({ entry, setChanged }: Props) => {
  const [changedNumber, setChangedNumber] = useState(false)
  const [fieldsNeeded, setFieldsNeeded] = useState(0)
  const [loading, setLoading] = useState(false)
  const [passwordState, setPasswordState] = useState(false)
  const [fields, setFields] = useState<number[]>([])
  const { toasts, setToasts, token } = useAppContext()

  const { setShow } = usePopUpContext()
  const { t } = useTranslation()
  const description = useRef<HTMLInputElement | null>(null)
  const wss = useRef<HTMLInputElement | null>(null)
  const identity = useRef<HTMLInputElement | null>(null)
  const password = useRef<HTMLInputElement | null>(null)
  const connectorNum = useRef<HTMLInputElement | null>(null)
  const backendSelected = useRef<HTMLSelectElement | null>(null)

  const physicalLimit = entry.config.physicalLimit
  const priority = entry.config.priority
  const desciptionArr = entry.config.descriptions

  const backends = entry.config.backends

  const updateCharginPoint = () => {
    setLoading(true)
    const arr = []
    const arrPriority = []
    const arrDescription = []
    const currents = document.getElementsByClassName("current")
    const currentPriority = document.getElementsByClassName("currentPriority")
    const currentDescription =
      document.getElementsByClassName("currentDescription")

    for (let i = 0; i < currents.length; i++) {
      arr.push(currents[i]["value"])
      arrPriority.push(priorityToNumber(currentPriority[i]["value"]))
      arrDescription.push(currentDescription[i]["value"])
    }

    axios
      .put(
        `${process.env.REACT_APP_API_ADDRESS}/chargingPoints/${entry.gwId}/${entry.id}`,
        {
          description: description.current?.value,

          config: {
            backend: {
              name: backendSelected.current?.value,
              id: getIdOfBackendFromName(
                backendSelected.current?.value,
                backends
              ),
              secure: wss.current?.checked,
              identity: identity.current?.value,
              password: password.current?.value,
            },
            connectorNum: connectorNum.current?.value,
            physicalLimit: arr,
            priority: arrPriority,
            descriptions: arrDescription,
          },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setShow(false)
        setChanged(true)
        setLoading(false)
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toastHandler(toasts, setToasts, false)
      })
  }

  useEffect(() => {
    const number =
      Number(connectorNum.current?.value) - entry.config.physicalLimit.length

    setFieldsNeeded(number)

    renderNewFiels()
    setChangedNumber(false)
  }, [changedNumber])

  const renderNewFiels = () => {
    const arr = []
    for (let i = 0; i < fieldsNeeded; i++) {
      arr.push(i)
    }
    setFields(arr)
  }

  const names: string[] = []
  backends.map((item: BackendsArrayType) => {
    names.push(item.name)
  })

  return (
    <>
      {!loading ? (
        <>
          <Box padding="14px 0 0 0" width={100}>
            <InputField
              width={100}
              type="text"
              label={t("Name")}
              value={entry.id}
              disabled={true}
              placeholder={t("Parking Place #4")}
            />
          </Box>
          <Box padding="14px 0 0 0" width={100}>
            <InputField
              width={100}
              type="text"
              label={t("Description")}
              value={entry["description"]}
              innerRef={description}
              placeholder={t("Description")}
            />
          </Box>
          <Box padding="14px 0 0 0">
            <Label blue={true} size="small">
              {t("Backend System Settings")}
            </Label>
          </Box>
          <Box padding="14px 0 0 0">
            <SelectField
              label={t("Backend System Settings")}
              placeholder={t("Select Backend System")}
              options={names}
              selected={entry.config.backend.name}
              innerRef={backendSelected}
              width={100}
            />
          </Box>
          <Box padding="14px 0 0 0">
            <CheckboxField
              label={t("Secure Connection (wss)")}
              width={100}
              checked={entry.config.backend.secure}
              innerRef={wss}
            />
          </Box>

          <Box direction="row" justify="space-between" padding="14px 0 0 0">
            <InputField
              type="text"
              width={48}
              label={t("Backend System Identity")}
              value={
                entry.config.backend.identity
                  ? entry.config.backend.identity
                  : entry.id
              }
              innerRef={identity}
              placeholder="XBGDHSZJLS"
            />
            <InputField
              type={passwordState ? "text" : "password"}
              width={40}
              label={t("Backend System Password")}
              value={entry.config.backend.password}
              innerRef={password}
            />
            <span style={{ height: "32px", width: "30px", alignSelf: "end" }}>
              <ButtonIcon
                icon={!passwordState ? <Eye /> : <EyeSlash />}
                onClick={() => setPasswordState(!passwordState)}
              />
            </span>
          </Box>
          <Box padding="14px 0 0 0">
            <Label blue={true} size="small">
              {t("Connector Settings")}
            </Label>
          </Box>
          <Box padding="14px 0 0 0" width={100}>
            <InputField
              width={100}
              type="number"
              min={1}
              label={t("Number of Charge Points")}
              value={entry.config.connectorNum}
              onChange={() => setChangedNumber(true)}
              innerRef={connectorNum}
            />
          </Box>
          <Box padding="14px 0 14px 0" direction="column">
            {physicalLimit[0] !== undefined &&
              physicalLimit.map((item: number, i: number) => {
                const negativeNumber = fieldsNeeded < 0
                const isLessThan = physicalLimit.length - 1 + fieldsNeeded < i
                const remove = negativeNumber && isLessThan
                if (!remove) {
                  return (
                    <>
                      <Box
                        direction="row"
                        justify="space-between"
                        padding="24px 0 0 0"
                        key={i + "limit"}
                      >
                        <InputField
                          type="text"
                          label={t("Connector No.")}
                          value={i + 1}
                          width={23}
                          disabled={true}
                        />
                        <InputField
                          type="text"
                          label={t("Power (kW): L1, L2, L3")}
                          disabled={true}
                          value={currentToPower(item)}
                          width={23}
                        />
                        <InputField
                          type="number"
                          label={t("Current (A): L1, L2, L3")}
                          value={item}
                          width={23}
                          className="current"
                        />
                        <SelectField
                          label={t("Priority")}
                          selected={priority[i]}
                          options={["Normal", "Medium", "High"]}
                          width={23}
                          className="currentPriority"
                        />
                      </Box>
                      <Box
                        direction="row"
                        justify="space-between"
                        padding="12px 0 0 0"
                        key={i + "description"}
                      >
                        <InputField
                          type="text"
                          placeholder={t("Description")}
                          value={desciptionArr[i]}
                          width={100}
                          className="currentDescription"
                        />
                      </Box>
                    </>
                  )
                }
              })}
            {fields.map((i) => (
              <>
                <Box
                  direction="row"
                  justify="space-between"
                  padding="24px 0 0 0"
                  key={i + "fields"}
                >
                  <InputField
                    type="text"
                    label={t("Connector No.")}
                    value={entry.config.physicalLimit.length + 1 + i}
                    disabled={true}
                    width={23}
                  />
                  <InputField
                    type="text"
                    label={t("Power (kW): L1, L2, L3")}
                    disabled={true}
                    width={23}
                  />
                  <InputField
                    className="current"
                    type="number"
                    label={t("Current (A): L1, L2, L3")}
                    width={23}
                  />
                  <SelectField
                    label={t("Priority")}
                    selected={"Normal"}
                    options={["Normal", "Medium", "High"]}
                    width={23}
                    className="currentPriority"
                  />
                </Box>
                <Box
                  direction="row"
                  justify="space-between"
                  padding="12px 0 0 0"
                >
                  <InputField
                    type="text"
                    placeholder={t("Description")}
                    width={100}
                    className="currentDescription"
                  />
                </Box>
              </>
            ))}
          </Box>
          <hr></hr>
          <Box justify="flex-end" padding="0 0 300px 0">
            <Button
              secondary={true}
              onClick={() => setShow(false)}
              text={t("Cancel")}
            />
            <Button
              margin="0 0 0 20px"
              onClick={() => updateCharginPoint()}
              text={t("Save")}
            />
          </Box>
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default ChargingStationConfigurationPopUp
