import { useTranslation } from "react-i18next"

export const MainPaths = () => {
  const { t } = useTranslation()

  return [
    {
      name: t("Infrastructure Dashboard"),
      value: "dashboard",
    },

    {
      name: t("Charging Stations"),
      value: "chargingStations",
    },
    {
      name: t("Powermeters"),
      value: "powermeters",
    },
    {
      name: t("Monitoring"),
      value: "monitoring",
    },
    {
      name: t("Configuration"),
      value: "configuration",
    },
  ]
}
