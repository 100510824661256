import axios from "axios"
import ButtonIcon from "components/Buttons/ButtonIcon"
import { Plus } from "components/Icons/Plus"
import { StyledGatewaySearchField } from "components/InputField/InputField.styled"
import Loader from "components/Loader/Loader"
import AddInfrastructurePopUp from "components/PopUp/AddInfrastructurePopUp"
import PopUp from "components/PopUp/PopUp"
import { Box } from "components/Shared/Box.styled"
import { FlexCol } from "components/Shared/FlexCol"
import { FlexRow } from "components/Shared/FlexRow"
import { GatewayLink } from "components/SideMenu/SideMenu.styled"
import Tooltip from "components/Tooltip/Tooltip"
import { Gateway } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { infrastructureStateIcon, toUpperCase } from "helpers/helpers"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { lightGray, white } from "styles/colors"
import { bold } from "styles/fonts"
import ChargingStationsMain from "Views/ChargingStationsMain/ChargingStationsMain"
import Configuration from "Views/Configuration/Configuration"
import Dashboard from "Views/Dashboard/Dashboard"
import MonitoringDashboard from "Views/MonitoringDasboard/MonitoringDashboard"
import PowermetersMain from "Views/PowermetersMain/PowermetersMain"
import { Link, Route, useLocation } from "wouter"

const Infrastructures = () => {
  const [data, setData] = useState<Gateway[]>([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState<string>("")
  const [show, setShow] = useState<boolean>(false)

  const location = useLocation()
  const { token } = useAppContext()
  const { t } = useTranslation()

  const infrastructures = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/gateways/gatewaysIdsNames`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
  }

  useEffect(() => {
    infrastructures()
    const interval = setInterval(() => {
      infrastructures()
    }, 60000)
    return () => clearInterval(interval)
  }, [token])

  const paths: string[] = location[0].split("/")
  const getCompanyName = (name: string) => {
    const paths: string[] = name.split("-")
    return toUpperCase(paths[0])
  }

  const onChange = () => {
    const value = document.querySelector("#GatewayId")
    value && setSearch(value["value"])
  }

  return (
    <FlexRow>
      <div
        style={{
          minWidth: "270px",
          maxWidth: "270px",
          height: "91vh",
          overflowY: "scroll",
          background: white,
        }}
      >
        <Box padding="0 0 0 12px">
          <ButtonIcon
            text="New infrastructure"
            onClick={() => setShow(true)}
            icon={
              <span style={{ marginRight: "10px" }}>
                <Plus />
              </span>
            }
          />
        </Box>
        {show && (
          <PopUp
            title={t("Add a new Infrastructure")}
            noConfig
            setShow={setShow}
          >
            <AddInfrastructurePopUp />
          </PopUp>
        )}
        {data.length > 5 && (
          <Box padding="0 20px" margin="20px 0">
            <StyledGatewaySearchField
              id="GatewayId"
              onChange={() => onChange()}
              placeholder={t("Search Gateway...")}
              type="search"
            />
          </Box>
        )}
        {!loading ? (
          <>
            {data.map((item, i) => {
              const id = item.name
                ? item.name.toLowerCase()
                : item.id.toLowerCase()
              const regex = new RegExp(search.toLowerCase())
              const checker =
                id.match(regex) || !!item.clientId?.includes(search)

              if (!checker) {
                return false
              }
              return (
                <Link href={`/infrastructure/${item.id}/dashboard`} key={i}>
                  <GatewayLink
                    style={{
                      margin: i === 0 ? "0 0 10px 0" : "10px 0",
                      backgroundColor: paths[2] === item.id ? lightGray : white,
                    }}
                  >
                    <div
                      style={{
                        width: "25px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {infrastructureStateIcon(
                        item.gwStatus,
                        item.proxyStatus,
                        item.lmStatus
                      )}
                    </div>
                    <FlexCol
                      style={{
                        marginLeft: "10px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      <Tooltip text={item.name ? item.name : item.id}>
                        <span
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            fontSize: "12px",
                            fontWeight: bold,
                          }}
                        >
                          {item.name ? item.name : item.id}
                        </span>
                      </Tooltip>
                      <span
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          fontSize: "12px",
                        }}
                      >
                        {item.clientId
                          ? item.clientId
                          : getCompanyName(item.id)}
                      </span>
                    </FlexCol>
                  </GatewayLink>
                </Link>
              )
            })}
          </>
        ) : (
          <Loader />
        )}
      </div>
      <div
        style={{
          padding: "12px 32px",
          width: "100%",
          height: "91vh",
          overflowY: "scroll",
        }}
      >
        <Route path="/infrastructure/:id/dashboard">
          {(params) => <Dashboard id={params.id} />}
        </Route>
        <Route path="/infrastructure/:id/chargingStations">
          {(params) => <ChargingStationsMain id={params.id} />}
        </Route>
        <Route path="/infrastructure/:id/powermeters">
          {(params) => <PowermetersMain id={params.id} />}
        </Route>
        <Route path="/infrastructure/:id/monitoring">
          {(params) => <MonitoringDashboard id={params.id} />}
        </Route>
        <Route path="/infrastructure/:id/configuration/:rest*">
          {(params) => <Configuration id={params.id} />}
        </Route>
      </div>
    </FlexRow>
  )
}

export default Infrastructures
