import axios from "axios"
import Button from "components/Buttons/Button"
import { Box } from "components/Shared/Box.styled"
import Table from "components/Table/Table"
import { TableRows } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import { usePopUpContext } from "helpers/PopupProvider"
import { TableProvider } from "helpers/TableProvider"
import { AddChargingPopup } from "helpers/TableStructures"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  gwId: string
  setLoadingParent: Dispatch<SetStateAction<boolean>>
  clusterId?: string
}

const AddChargingStationPopUp = ({
  gwId,
  setLoadingParent,
  clusterId,
}: Props) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [used, setUsed] = useState([])
  const [devices, setDevices] = useState<string[]>([])
  const [oldDevices, setOldDevices] = useState<string[]>([])

  const { t } = useTranslation()
  const { toasts, setToasts, token } = useAppContext()

  const clusters = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setUsed(res.data.assignedDevices)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const { setShow } = usePopUpContext()
  const url = clusterId
    ? `${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}/${clusterId}`
    : `${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}`

  const updateLevel = () => {
    setLoading(true)
    setLoadingParent(true)

    const arr = []
    const selects = document.getElementsByClassName(
      "selectColumn"
    ) as HTMLCollectionOf<HTMLInputElement>

    for (let i = 0; i < selects.length; i++) {
      if (selects[i].checked) {
        arr.push(selects[i].id)
      }
    }

    axios
      .put(
        url,
        {
          devices: arr,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setShow(false)
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        setShow(false)
        toastHandler(toasts, setToasts, false)
      })
    setLoading(false)
  }

  const getDevices = () => {
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setDevices(res.data.devices)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const gateways = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/chargingPoints/${gwId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    gateways()
    const interval = setInterval(() => {
      clusters()
      gateways()
    }, 5000)
    return () => clearInterval(interval)
  }, [token])

  useEffect(() => {
    clusters()
    getDevices()
  }, [loading])

  return (
    <>
      <TableProvider
        noAction={true}
        devices={devices}
        setLoading={setLoading}
        usedDevices={used}
        disabledCheck={true}
        oldDevices={oldDevices}
        setOldDevices={setOldDevices}
      >
        <div style={{ maxHeight: "600px", overflowY: "auto" }}>
          <Table
            layout={AddChargingPopup}
            title={t("Chargepoints")}
            data={data as TableRows[]}
            updatePath={`${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}`}
            selectCell={true}
            loading={loading}
          />
        </div>
      </TableProvider>

      <Box justify="flex-end" padding="20px 0 0 0 ">
        <Button
          secondary={true}
          onClick={() => setShow(false)}
          text={t("Cancel")}
        />
        <Button
          margin="0 0 0 20px"
          onClick={() => updateLevel()}
          text={t("Save")}
        />
      </Box>
    </>
  )
}

export default AddChargingStationPopUp
