import { CloudSlash } from "components/Icons/CloudSlash"
import { Error } from "components/Icons/Error"
import { Online } from "components/Icons/Online"
import BoldRow from "components/Table/Rows/BoldRow"
import CheckRow from "components/Table/Rows/CheckRow"
import CPMSRow from "components/Table/Rows/CPMSRow"
import CurrentPowerRow from "components/Table/Rows/CurrentPowerRow"
import DateTimeRow from "components/Table/Rows/DateTimeRow"
import Row from "components/Table/Rows/Row"
import ScrollRow from "components/Table/Rows/ScrollRow"
import SelectRow from "components/Table/Rows/SelectRow"
import StatusRow from "components/Table/Rows/StatusRow"
import TripleRow from "components/Table/Rows/TripleRow"
import {
  BackendsArrayType,
  Chargepoint,
  ClusterTableFilter,
  Gateway,
  GatewayPayload,
  TableRows,
  Toasts,
} from "components/types/types"
import moment from "moment"
import { Dispatch, SetStateAction } from "react"
import React from "react"
import { orange, pending, primary, red, success, warning } from "styles/colors"

export const toastHandler = (
  toasts: Toasts[],
  setToasts: Dispatch<SetStateAction<Toasts[]>>,
  message: boolean,
  customMessage?: string
) => {
  if (toasts.length > 4) {
    toasts.shift()
  }
  const dateTime = moment().format("HH:mm:ss YY/MM/DD")
  const type = message ? "success" : "failure"
  const arr = toasts

  arr.push({ type: type, date: dateTime, customMessage: customMessage })
  setToasts([...arr])
}

export const checkIndexOfFilter = (
  index: number,
  clusterTableFilter: ClusterTableFilter[]
) => {
  if (clusterTableFilter.filter((e) => e.index === index).length > 0)
    return true

  return false
}

export const getIdOfBackendFromName = (
  name: string | undefined,
  backends: BackendsArrayType[]
) => {
  const i = backends.findIndex((e) => e.name === name)

  return backends[i].id
}

export const getIndexOfFilter = (
  index: number,
  clusterTableFilter: ClusterTableFilter[]
) => {
  return clusterTableFilter.findIndex((e) => e.index === index)
}

export const spliceIndexOfFilter = (
  index: number,
  clusterTableFilter: ClusterTableFilter[],
  filter: string
) => {
  const arr = clusterTableFilter
  if (checkIndexOfFilter(index, clusterTableFilter)) {
    arr.splice(getIndexOfFilter(index, clusterTableFilter), 1)
    arr.push({ index: index, value: filter })
    return arr
  }

  arr.push({ index: index, value: filter })
  return arr
}

export const currentToPower = (current: number) => {
  return (Number(3 * current * 230) / 1000).toFixed(1)
}

export const currentToPowerNoneMultiply = (current: number) => {
  return (Number(isNaN(current) ? 0 : current * 230) / 1000).toFixed(1)
}

export const currentDecimals = (current: number, precision?: number) => {
  return Number(isNaN(current) ? 0 : current).toFixed(
    precision == undefined ? 1 : precision
  )
}

export const setSafeLimitColor = (gw: Gateway) => {
  if (
    gw.dynamicLoadManagement === undefined ||
    gw.dynamicLoadManagement.fixedClusterLimit === undefined ||
    gw.dynamicLoadManagement.safetyClusterLimit === undefined
  ) {
    return primary
  } else {
    if (
      gw.dynamicLoadManagement.safetyClusterLimit <
        gw.dynamicLoadManagement.dynamicClusterLimit &&
      gw.dynamicLoadManagement.safetyClusterLimit <
        gw.dynamicLoadManagement.fixedClusterLimit
    ) {
      return warning
    } else if (
      gw.dynamicLoadManagement.safetyClusterLimit <
        gw.dynamicLoadManagement.fixedClusterLimit &&
      !gw.dynamicLoadManagement.enabled
    ) {
      return warning
    } else return primary
  }
}

export const toUpperCase = (string: string) => {
  return string[0].toUpperCase() + string.substring(1)
}

export const getCpsInState = (cps: Chargepoint[], state: string) => {
  let cnt = 0
  cps.forEach((cp) => {
    cp.cpState.forEach((cpState) => {
      if (cpState === state) {
        cnt = cnt + 1
      }
    })
  })
  return cnt
}

export const getNumCpsInConnectionState = (
  cps: Chargepoint[],
  state: string
) => {
  let cnt = 0
  cps.forEach((cp) => {
    if (Array.isArray(cp.connection)) {
      cp.connection.forEach((connState) => {
        if (connState === state) {
          cnt = cnt + 1
        }
      })
    } else {
      if (cp.connection === state) {
        cnt = cnt + 1
      }
    }
  })
  return cnt
}

export const priorityToString = (priority: number) => {
  switch (priority) {
    case 0:
      return "Low"
      break
    case 1:
      return "Medium"
      break
    case 2:
      return "High"
      break
    default:
      return "Overruled"
  }
}

export const priorityToNumber = (priority: string) => {
  return priority === "Normal" ? 0 : priority === "Medium" ? 1 : 2
}

export const TableCellFormmater = (
  type: string,
  index: string,
  entry: TableRows,
  uniqueKey: string,
  measure: string | undefined,
  width: number,
  disabled: boolean
) => {
  const additional = index.includes(".")
  const originalIndex = index.substring(0, index.indexOf("."))
  const additionalIndex = index.substring(index.indexOf(".") + 1)
  /* eslint-disable indent */
  switch (type) {
    case "status":
      return (
        <StatusRow
          status={entry["connection"] && entry["connection"]}
          text={entry[index]}
          width={width}
          key={`${uniqueKey}status`}
        />
      )
    case "CPMS":
      return (
        <CPMSRow
          status={
            entry["backendConnection"] &&
            entry["backendConnection"] === "connected"
          }
          text={entry["config"]["backend"]["name"]}
          width={width}
          key={`${uniqueKey}cpms`}
        />
      )
    case "priority":
      return (
        <SelectRow
          entry={entry as GatewayPayload}
          priority={entry[index]}
          disabled={disabled}
          width={width}
          key={`${uniqueKey}select`}
        />
      )
    case "bold":
      return (
        <BoldRow text={entry[index]} width={width} key={`${uniqueKey}bold`} />
      )
    case "check":
      return (
        <CheckRow
          disabled={true}
          checked={entry[index]}
          width={width}
          key={`${uniqueKey}check`}
        />
      )
    case "dateTime":
      return (
        <DateTimeRow
          dateTime={entry[index]}
          width={width}
          key={`${uniqueKey}datetime`}
        />
      )
    case "scroll":
      return (
        <ScrollRow width={width} key={`${uniqueKey}scroll`}>
          <p>
            {entry["config"]["physicalLimit"].map((item: number, i: number) => (
              <span key={i}>{i + 1 + ": " + item}&emsp;</span>
            ))}
          </p>
        </ScrollRow>
      )
    case "currentPower":
      return (
        <CurrentPowerRow
          current={entry[index] as number}
          width={width}
          key={`${uniqueKey}currentPower`}
        />
      )
    case "state":
      return (
        <Row
          text={
            entry["connection"] === "disconnected"
              ? "Disconnected"
              : entry[index][0] === "init"
              ? "Not configured"
              : entry[index][0]
          }
          width={width}
          key={`${uniqueKey}state`}
        />
      )
    case "triplePower":
      return entry["lastValues"] ? (
        <TripleRow
          l1={entry["lastValues"]["realPowerL1"]}
          l2={entry["lastValues"]["realPowerL2"]}
          l3={entry["lastValues"]["realPowerL3"]}
          power={entry["lastValues"]["realPowerSum"]}
          unit={measure}
          width={width}
          key={`${uniqueKey}triplePower`}
        />
      ) : (
        <td>N/A</td>
      )
    case "tripleCurrent":
      return entry["lastValues"] ? (
        <TripleRow
          l1={entry["lastValues"]["apparentCurrentL1"]}
          l2={entry["lastValues"]["apparentCurrentL2"]}
          l3={entry["lastValues"]["apparentCurrentL3"]}
          unit={measure}
          width={width}
          key={`${uniqueKey}tripleCurrent`}
        />
      ) : (
        <td>N/A</td>
      )
    case "tripleVoltage":
      return entry["lastValues"] ? (
        <TripleRow
          l1={entry["lastValues"]["voltageL1"]}
          l2={entry["lastValues"]["voltageL2"]}
          l3={entry["lastValues"]["voltageL3"]}
          unit={measure}
          width={width}
          key={`${uniqueKey}tripleVoltage`}
        />
      ) : (
        <td>N/A</td>
      )
    case "tripleAllocated":
      return (
        <TripleRow
          l1={
            entry["allocated"] && entry["allocated"][0]
              ? entry["allocated"][0]["l1"]
              : 0
          }
          l2={
            entry["allocated"] && entry["allocated"][0]
              ? entry["allocated"][0]["l2"]
              : 0
          }
          l3={
            entry["allocated"] && entry["allocated"][0]
              ? entry["allocated"][0]["l3"]
              : 0
          }
          calculate={true}
          unit={measure}
          width={width}
          key={`${uniqueKey}tripleActual`}
        />
      )
    case "tripleActual":
      // eslint-disable-next-line no-case-declarations
      const actual = entry["actual"][0]
      return (
        <TripleRow
          l1={actual ? actual["l1"] : 0}
          l2={actual ? actual["l2"] : 0}
          l3={actual ? actual["l3"] : 0}
          power={Number(actual ? actual["powerActiveImport"] : 0)}
          calculate={false}
          unit={measure}
          width={width}
          soc={actual ? actual["soc"] : 0}
          key={`${uniqueKey}tripleActual`}
        />
      )
    case "tripleLimit":
      return (
        <TripleRow
          l1={entry["config"]["physicalLimit"][0]}
          l2={entry["config"]["physicalLimit"][0]}
          l3={entry["config"]["physicalLimit"][0]}
          calculate={true}
          unit={measure}
          width={width}
          key={`${uniqueKey}tripleLimit`}
        />
      )
    default:
      return (
        <Row
          text={
            additional && entry[originalIndex]
              ? entry[originalIndex][additionalIndex]
              : entry[index]
          }
          measure={measure}
          width={width}
          key={`${uniqueKey}default`}
        />
      )
  }
}

export const mapServiceName = (serviceId: string) => {
  if (serviceId === "lmd") {
    return "Load Manager"
  } else if (serviceId === "mmi") {
    return "Modbus"
  } else if (serviceId === "ocpp-proxy") {
    return "OCPP Proxy"
  } else {
    return "Unknown"
  }
}

export const pagninationString = (
  bool: boolean,
  pageSize: number,
  pageNumber: number
) =>
  `${bool ? "&" : "?"}pagination={"pageSize": ${Number(
    pageSize
  )}, "page": ${Number(pageNumber)}}`

export const statusFormatter = (
  gwStatus: string,
  proxyStatus: string,
  lmdStatus: string
) => {
  let status = ""
  if (gwStatus === "Inactive") {
    return "OFFLINE"
  } else if (proxyStatus === "Active" && lmdStatus === "Active") {
    status = "OK"
  } else if (proxyStatus === "Error" || lmdStatus === "Error") {
    status = "ERROR"
  } else {
    status = "WARN"
  }
  return status
}

export const statusColor = (status: string) => {
  if (status === "Error") {
    return red
  } else if (status === "Warning") {
    return warning
  } else if (status === "Inactive") {
    return warning
  } else if (status === "Restarting") {
    return orange
  } else if (status === "Pending") {
    return pending
  } else {
    return success
  }
}
export const combinedStatusColor = (
  gwStatus: string,
  proxyStatus: string,
  lmdStatus: string
) => {
  if (gwStatus == "Inactive") {
    return red
  } else if (proxyStatus === "Error" || lmdStatus === "Error") {
    return red
  } else if (
    gwStatus === "Active" &&
    proxyStatus === "Active" &&
    lmdStatus === "Active"
  ) {
    return success
  } else {
    return warning
  }
}

export const statusColorPowermeter = (connection: string) => {
  if (connection === "disconnected") {
    return red
  } else if (connection === "reconnecting") {
    return warning
  } else {
    return success
  }
}

export const infrastructureStateIcon = (
  gwStatus: string,
  proxyStatus: string,
  lmdStatus: string
) => {
  if (gwStatus == "Inactive") {
    return <CloudSlash />
  } else if (proxyStatus == "Error" || lmdStatus == "Error") {
    return <Error />
  } else if (proxyStatus == "Inactive" || lmdStatus == "Inactive") {
    return <Error />
  } else {
    return <Online />
  }
}
