import { Box } from "components/Shared/Box.styled"
import React from "react"
import { small, standard, xsmall, xxsmall } from "styles/fonts"

import { StyledDashboardCard } from "./Card.styled"

interface Props {
  icon: React.ReactNode
  title: string
  subTitle?: string
  children: React.ReactNode
  offline?: boolean
}
const Card = ({ icon, title, subTitle, children, offline }: Props) => {
  return (
    <StyledDashboardCard offline={offline}>
      <Box direction="row" align="center">
        {icon}
        <Box direction="column">
          <span
            style={{
              marginLeft: "10px",
              fontSize: subTitle ? xsmall : small,
              fontWeight: standard,
            }}
          >
            {title}
          </span>
          {subTitle ? (
            <span
              style={{
                marginLeft: "10px",
                fontSize: xxsmall,
                fontWeight: standard,
              }}
            >
              {subTitle}
            </span>
          ) : (
            ""
          )}
        </Box>
      </Box>
      {children}
    </StyledDashboardCard>
  )
}

export default Card
