import axios from "axios"
import { CardTable, StyledCardContainer } from "components/Card/Card.styled"
import ChargingStationConfigurationPopUp from "components/PopUp/ChargingStationConfigurationPopUp"
import { Box } from "components/Shared/Box.styled"
import { MainPaths } from "components/Shared/MainPaths"
import SubMenu from "components/SubMenu/SubMenu"
import Table from "components/Table/Table"
import { Chargepoint, TableRows } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { TableProvider } from "helpers/TableProvider"
import { GatewayTable } from "helpers/TableStructures"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  id: string
}

const ChargingStationsMain = ({ id }: Props) => {
  const [searchString, setSearchString] = useState("")
  const [search, setSearch] = useState("")
  const [pageSizeChargePoints, setPageSizeChargePoints] = useState(10)
  const [pageNumberChargePoints, setPageNumberChargePoints] = useState(1)
  const [totalSizeChargePoints, setTotalSizeChargePoints] = useState(1)
  const [totalPageChargePoints, setTotalPageChargePoints] = useState(1)
  const [chargepointData, setChargepointData] = useState<Chargepoint[]>([])
  const [changed, setChanged] = useState(false)

  const [loadingChargePoints, setLoadingChargingStations] = useState(true)
  const { t } = useTranslation()
  const { token } = useAppContext()

  const chargingStations = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/chargingPoints/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setTotalSizeChargePoints(Number(res.headers["pagination-total"]))
        setTotalPageChargePoints(Number(res.headers["pagination-totalpages"]))
        setChargepointData(res.data)
        setLoadingChargingStations(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingChargingStations(false)
      })
  }

  useEffect(() => {
    setChanged(false)
    chargingStations()
    const interval = setInterval(() => {
      chargingStations()
    }, 20000)
    return () => clearInterval(interval)
  }, [token, changed])

  useEffect(() => {
    chargingStations()
    const interval = setInterval(() => {
      chargingStations()
    }, 20000)
    return () => clearInterval(interval)
  }, [token])

  return (
    <>
      <SubMenu items={MainPaths()} id={id} main={true} />
      <StyledCardContainer>
        <CardTable>
          <TableProvider
            searchString={setSearchString}
            search={setSearch}
            searchValue={searchString}
            priority={false}
            showAdjust={true}
            double={true}
            power={true}
            gwId={true}
            notification={true}
            popUpData={(entry: TableRows) => (
              <ChargingStationConfigurationPopUp
                setChanged={setChanged}
                entry={entry as Chargepoint}
              />
            )}
          >
            <Box margin="0 0 40px 0">
              <Table
                layout={GatewayTable}
                data={
                  chargepointData
                    .filter((cp) => cp.id.includes(searchString))
                    .slice(
                      (pageNumberChargePoints - 1) * pageSizeChargePoints,
                      pageNumberChargePoints * pageSizeChargePoints
                    ) as TableRows[] as TableRows[]
                }
                loading={loadingChargePoints}
                toolbar={true}
                title={t("Charging Stations")}
                pageSize={pageSizeChargePoints}
                setPageSize={setPageSizeChargePoints}
                pageNumber={pageNumberChargePoints}
                setPageNumber={setPageNumberChargePoints}
                totalSize={chargepointData.length}
                totalPage={Math.ceil(
                  chargepointData.length / pageSizeChargePoints
                )}
              />
            </Box>
          </TableProvider>
        </CardTable>
      </StyledCardContainer>
    </>
  )
}

export default ChargingStationsMain
