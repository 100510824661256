import ButtonIcon from "components/Buttons/ButtonIcon"
import { Adjust } from "components/Icons/Adjust"
import { ArrowDown } from "components/Icons/ArrowDown"
import MainLevelConfigurationPopup from "components/PopUp/MainLevelConfigurationPopup"
import PopUp from "components/PopUp/PopUp"
import { Box } from "components/Shared/Box.styled"
import { ClusterConfigsType } from "components/types/types"
import { useTreeContext } from "helpers/TreeProvider"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { secondaryLight } from "styles/colors"
import { xsmall } from "styles/fonts"

import { MainPoint } from "./Tree.styled"
import { TreeDropdownChargepoints } from "./TreeDropdownChargepoints"
import { TreeDropdownDynamicLoad } from "./TreeDropdownDynamicLoad"
import { TreeDropdownPowermeters } from "./TreeDropdownPowermeters"

interface Props {
  payload: ClusterConfigsType
  id: string
  open: boolean
}
export const TreeMain = ({ payload, id, open }: Props) => {
  const [show, setShow] = useState(open)
  const [showConfig, setShowConfig] = useState(false)

  const { t } = useTranslation()
  const { setOpenClusters, openClusters } = useTreeContext()
  const setShowState = () => {
    const arr = openClusters
    if (show) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === 100) {
          arr.splice(i, 1)
        }
      }
    } else {
      arr.push(100)
    }
    setShow(!show)
    setOpenClusters(arr)
  }

  return (
    <>
      <MainPoint>
        <Box direction="row" align="center" width={20}>
          <ButtonIcon
            onClick={() => setShowState()}
            rotate={show}
            icon={<ArrowDown />}
          />
          <p style={{ fontSize: xsmall }}>{payload?.name}</p>
        </Box>
        <Box direction="row" justify="space-around" width={30}>
          <p style={{ fontSize: xsmall }}>{payload?.maximumCurrent}</p>
        </Box>
        <Box direction="row" width={40}>
          {/* <p style={{ fontSize: xsmall }}>
          {payload?.distributionScheme}</p> */}
        </Box>
        <Box direction="row" width={10}>
          <ButtonIcon
            onClick={() => setShowConfig(!showConfig)}
            icon={<Adjust />}
          />
        </Box>
      </MainPoint>
      {show && (
        <span style={{ background: secondaryLight }}>
          <TreeDropdownChargepoints gwId={payload.gwId} />
          <TreeDropdownPowermeters gwId={payload.gwId} />
          <TreeDropdownDynamicLoad gwId={payload.gwId} />
        </span>
      )}
      {showConfig && (
        <PopUp title={t("Main Level")} setShow={setShowConfig}>
          <MainLevelConfigurationPopup id={id} entry={payload} />
        </PopUp>
      )}
    </>
  )
}
