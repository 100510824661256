import { Box } from "components/Shared/Box.styled"
import { Label } from "components/Shared/Label.styled"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { black, disabled, inputShadow, primary, white } from "styles/colors"
import { xsmall } from "styles/fonts"
import { radius, x1, x2 } from "styles/sizes"

interface DateRangePickerProps {
  startDate?: string
  endDate?: string
  disabledStyle?: boolean
  label?: string
  onChange: (startDate: string, endDate: string) => void
}

const DateRangePickerContainer = styled.div`
  display: flex;
  align-items: center;
`

const DateInput = styled.input<{ disabledStyle?: boolean }>`
  height: 22px;
  background: ${white};
  border: 1px solid ${primary};
  color: ${black};
  padding: ${x1} ${x2};
  border-radius: ${radius};
  font-size: ${xsmall};
  box-shadow: ${inputShadow};

  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: text;
  }

  ${(props) =>
    props.disabledStyle &&
    css`
      pointer-events: none;
      color: ${disabled};
      border: 1px solid ${disabled};
    `};
`

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  disabledStyle,
  label,
  onChange,
}) => {
  const [start, setStart] = useState(startDate || "")
  const [end, setEnd] = useState(endDate || "")

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStart(e.target.value)
    onChange(e.target.value, end)
  }

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnd(e.target.value)
    onChange(start, e.target.value)
  }

  return (
    <Box direction={"column"} align={"start"} style={{}}>
      {label && (
        <Label
          size="small"
          style={{ textAlign: "left", marginRight: 0 }}
        >{`${label}`}</Label>
      )}
      <DateRangePickerContainer>
        <DateInput
          type="date"
          value={start}
          onChange={handleStartDateChange}
          disabled={disabledStyle}
        />
        <span style={{ margin: "0 10px" }}>to</span>
        <DateInput
          type="date"
          value={end}
          onChange={handleEndDateChange}
          disabled={disabledStyle}
        />
      </DateRangePickerContainer>
    </Box>
  )
}

export default DateRangePicker
