import CognitoIdentityProvider from "aws-sdk/clients/cognitoidentityserviceprovider"
import Button from "components/Buttons/Button"
import Card from "components/Card/Card"
import { StyledDashboardCardContainer } from "components/Card/Card.styled"
import { Mfa } from "components/Icons/Mfa"
import { User } from "components/Icons/User"
import InputField from "components/InputField/InputField"
import PopUp from "components/PopUp/PopUp"
import { Box } from "components/Shared/Box.styled"
import { FlexCol } from "components/Shared/FlexCol"
import { UserInfo, useUserContext } from "helpers/UserContextProvider"
import { QRCodeSVG } from "qrcode.react"
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react"
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce"

interface UserInformationProps {
  email: string
  setError: (error: string) => void
  userInfo: UserInfo
}

interface MFAConfigurationProps {
  configureMFA: () => void
  setError: (error: string) => void
  mfaSetup: boolean
}

interface QRPopupProps {
  username: string
  secretCode: string
  onSubmit: (code: string) => void
  setShow: Dispatch<SetStateAction<boolean>>
  setError: (error: string) => void
}

interface ExpertSettings {
  expertSettingsEnabled: boolean
  changeExpertSettings: (enabled: boolean) => void
}

const UserInformation: React.FC<UserInformationProps> = ({
  email,
  userInfo,
}) => (
  <Card title="User Information" icon={<User />}>
    <Box direction="column" margin="10px 10px 10px 10px">
      <span>Email: {email}</span>
      <span>
        Role:{" "}
        {(userInfo.groups[0] && userInfo.groups[0].role) === "admin"
          ? "Administrator"
          : "User"}
      </span>
    </Box>
  </Card>
)

const MFAConfiguration: React.FC<MFAConfigurationProps> = ({
  configureMFA,
  mfaSetup,
}) => {
  return (
    <Card title="MFA Configuration" icon={<Mfa />}>
      <Box direction="column" margin="10px 10px 10px 10px">
        <p>MFA Status: {mfaSetup ? "Enabled" : "Disabled"}</p>
        <Button onClick={configureMFA} text="Configure MFA" />
      </Box>
    </Card>
  )
}

const ExpertSettings: React.FC<ExpertSettings> = ({
  expertSettingsEnabled,
  changeExpertSettings,
}) => {
  return (
    <Card title="Configuration settings" icon={<Mfa />}>
      <Box direction="column" margin="10px 10px 10px 10px">
        <p>
          Expert settings enabled:{" "}
          {expertSettingsEnabled ? "Enabled" : "Disabled"}
        </p>
        <Button
          onClick={() => changeExpertSettings(!expertSettingsEnabled)}
          text={`${
            expertSettingsEnabled ? "Disable" : "Enable"
          } expert settings`}
        />
      </Box>
    </Card>
  )
}

const QrPopup: React.FC<QRPopupProps> = ({
  username,
  secretCode,
  onSubmit,
  setShow,
}) => {
  const userCode = useRef<HTMLInputElement>(null)
  const qrCode = `otpauth://totp/Floading:${username}?secret=${secretCode}`

  return (
    <PopUp title={"Set up software token"} noConfig setShow={setShow}>
      <p>Scan the QR code inside your app</p>
      <Box
        direction="column"
        justify="center"
        align="center"
        padding="20px 0 0 0"
      >
        <QRCodeSVG value={qrCode} />
      </Box>
      <Box direction="column" justify="center" padding="20px 0 20px 0">
        <InputField type="text" innerRef={userCode} label="Enter Your Code" />
      </Box>
      <Box direction="column" justify="center" padding="0 0 20px 0">
        <Button
          text="Submit"
          onClick={() => {
            const value = userCode.current?.value
            if (value) onSubmit(userCode.current?.value || "")
          }}
        />
        <Button text="Dismiss" onClick={() => setShow(false)} />
      </Box>
    </PopUp>
  )
}
const UserProfile: React.FC = () => {
  const [error, setError] = useState<string>("")
  const [secretCode, setSecretCode] = useState<string>("")
  const [showQRPopup, setShowQRPopup] = useState<boolean>(false)
  const { token, idTokenData } = useContext<IAuthContext>(AuthContext)
  const { userInfo, updateUserInfo } = useUserContext()
  const cognitoidentityserviceprovider = new CognitoIdentityProvider({
    apiVersion: "2016-04-18",
    region: process.env.REACT_APP_REGION,
  })

  const getSecretCode = async () => {
    await cognitoidentityserviceprovider
      .associateSoftwareToken({ AccessToken: token }, (err, data) => {
        if (err) {
          console.log(err, err.stack)
          setError(err.message)
        } // an error occurred
        else {
          setSecretCode(data.SecretCode || "")
          setShowQRPopup(true)
        }
      })
      .promise()
  }

  const verifyMFA = async (userCode: string) => {
    const params = {
      AccessToken: token,
      UserCode: userCode,
    }

    await cognitoidentityserviceprovider
      .verifySoftwareToken(params, function (err, data) {
        if (err) {
          console.log(err, err.stack)
          setError(err.message)
        } // an error occurred
        else {
          return handleConfigureMFA()
        }
      })
      .promise()
  }

  const handleConfigureMFA = async () => {
    const params = {
      AccessToken: token /* required */,
      SMSMfaSettings: {
        Enabled: false,
        PreferredMfa: false,
      },
      SoftwareTokenMfaSettings: {
        Enabled: true,
        PreferredMfa: true,
      },
    }
    await cognitoidentityserviceprovider
      .setUserMFAPreference(params, function (err, data) {
        if (err) {
          console.log(err, err.stack)
          setError(err.message)
        } // an error occurred
        else {
          setShowQRPopup(false)
        } // successful response
      })
      .promise()
  }

  return (
    <Box direction="column" margin="20px 20px 20px 20px">
      <FlexCol>
        {showQRPopup && (
          <QrPopup
            username={idTokenData?.email}
            secretCode={secretCode}
            setShow={setShowQRPopup}
            onSubmit={verifyMFA}
            setError={setError}
          />
        )}
        {error != "" && (
          <PopUp title={"Error"} noConfig setShow={() => setError("")}>
            <Box>
              <span>{error}</span>
            </Box>
            <Button text="Dismiss" onClick={() => setError("")} />
          </PopUp>
        )}
        <StyledDashboardCardContainer>
          {userInfo && (
            <>
              <UserInformation
                email={idTokenData?.email || ""}
                setError={setError}
                userInfo={userInfo}
              />
              <ExpertSettings
                expertSettingsEnabled={userInfo.expertSettingsEnabled}
                changeExpertSettings={(enabled: boolean) => {
                  updateUserInfo(enabled)
                }}
              />
            </>
          )}
          <MFAConfiguration
            configureMFA={getSecretCode}
            setError={setError}
            mfaSetup={userInfo.mfaSetup}
          />
        </StyledDashboardCardContainer>
      </FlexCol>
    </Box>
  )
}

export default UserProfile
