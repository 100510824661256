import styled from "styled-components"

export const StyledLoader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 200px;
  align-items: center;
  img {
    width: 191px;
    height: 100px;
  }
`

import Loading from "./Loading.gif"
import React from "react"


const Loader = () => (
  <StyledLoader>
    <img src={Loading} alt="" />
  </StyledLoader>
)

export default Loader

