import "./App.css"

import Container from "components/Container/Container"
import Content from "components/Content/Content"
import Menu from "components/Menu/Menu"
import SideMenu from "components/SideMenu/SideMenu"
import ToastContainer from "components/Toast/ToastContainer"
import { Toasts } from "components/types/types"
import { AppProvider } from "helpers/AppProvider"
import { UserContextProvider } from "helpers/UserContextProvider"
import React, { useState } from "react"
import {
  AuthProvider,
  TAuthConfig,
  TRefreshTokenExpiredEvent,
} from "react-oauth2-code-pkce"

console.assert(process.env.REACT_APP_CLIENT_ID, "Missing client id")
console.assert(
  process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
  "Missing auth endpoint"
)
console.assert(process.env.REACT_APP_TOKEN_ENDPOINT, "Missing token endpoint")
console.assert(process.env.REACT_APP_REDIRECT_URL, "Missing redirect url")
console.assert(process.env.REACT_APP_SCOPE, "Missing scope")
console.assert(process.env.REACT_APP_LOGOUT_URL, "Missing logout url")
console.assert(process.env.REACT_APP_REDIRECT_URL, "Missing redirect url")

const authConfig: TAuthConfig = {
  storage: "session",
  clientId: process.env.REACT_APP_CLIENT_ID || "",
  authorizationEndpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT || "",
  tokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT || "",
  redirectUri: process.env.REACT_APP_REDIRECT_URL || "",
  scope: process.env.REACT_APP_SCOPE || "",
  logoutEndpoint: process.env.REACT_APP_LOGOUT_URL || "",
  extraLogoutParameters: {
    redirect_uri: process.env.REACT_APP_REDIRECT_URL || "",
    response_type: "code",
  },
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) =>
    window.confirm(
      "Session expired. Refresh page to continue using the site?"
    ) && event.login(),
}

function App() {
  const [toasts, setToasts] = useState<Toasts[]>([])

  return (
    <div className="App">
      <AuthProvider authConfig={authConfig}>
        <AppProvider setToasts={setToasts} toasts={toasts}>
          <UserContextProvider>
            <ToastContainer toasts={toasts} />
            <Menu />
            <Container>
              <SideMenu />
              <Content />
            </Container>
          </UserContextProvider>
        </AppProvider>
      </AuthProvider>
    </div>
  )
}

export default App
