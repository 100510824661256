import axios from "axios"
import Button from "components/Buttons/Button"
import ButtonIcon from "components/Buttons/ButtonIcon"
import { ArrowDown } from "components/Icons/ArrowDown"
import InputField from "components/InputField/InputField"
import SelectField from "components/InputField/SelectField"
import Loader from "components/Loader/Loader"
import { Box } from "components/Shared/Box.styled"
import { Label } from "components/Shared/Label.styled"
import { Warning } from "components/Shared/Warning"
import { ClusterConfigsType } from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import { toastHandler } from "helpers/helpers"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { TreeSubDropdown } from "./Tree.styled"

interface Props {
  gwId: string
}

export const TreeDropdownDynamicLoad = ({ gwId }: Props) => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<ClusterConfigsType | undefined>(undefined)
  const [changed, setChanged] = useState(false)

  const { t } = useTranslation()
  const { toasts, setToasts, token } = useAppContext()

  const solarMode = useRef<HTMLSelectElement | null>(null)
  const solarPower = useRef<HTMLInputElement | null>(null)
  const solarEnergy = useRef<HTMLInputElement | null>(null)

  const buildingMode = useRef<HTMLSelectElement | null>(null)
  const buildingTreshold = useRef<HTMLInputElement | null>(null)
  const buildingLimit = useRef<HTMLInputElement | null>(null)

  const peakMode = useRef<HTMLSelectElement | null>(null)
  const peakInterval = useRef<HTMLInputElement | null>(null)

  const clusters = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
  }

  useEffect(() => {
    clusters()
    const interval = setInterval(() => {
      clusters()
    }, 5000)
    return () => clearInterval(interval)
  }, [loading, token])

  const updateClusterConfig = () => {
    setLoading(true)
    axios
      .put(
        `${process.env.REACT_APP_API_ADDRESS}/clusterConfigs/${gwId}`,
        {
          solarBasedChargingConfig: {
            mode: solarDeformat(solarMode.current?.value),
            basePower: solarPower.current?.value,
            baseEnergy: solarEnergy.current?.value,
          },
          buildingBasedChargingConfig: {
            mode: buildingMode.current?.value,
            maximumTreshold: buildingTreshold.current?.value,
            maximumCurrentLimit: buildingLimit.current?.value,
          },
          peakDemandPreventionConfig: {
            mode: peakMode.current?.value,
            maximumEnergyDemandPer15Min: peakInterval.current?.value,
          },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
        setChanged(false)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        toastHandler(toasts, setToasts, false)
        setChanged(false)
        setLoading(false)
      })
  }

  const solarDeformat = (solar: string | undefined) => {
    if (solar === "Disabled") {
      return "none"
    } else if (solar === "Base-Current") {
      return "basecurrent"
    } else if (solar === "Base-Energy") {
      return "baseenergy"
    }
  }

  const solarFormatted = (solar: string | undefined) => {
    if (solar === "none") {
      return "Disabled"
    } else if (solar === "basecurrent") {
      return "Base-Current"
    } else if (solar === "baseenergy") {
      return "Base-Energy"
    }
  }

  return (
    <>
      <TreeSubDropdown
        style={{
          marginBottom: "20px",
        }}
      >
        <ButtonIcon
          onClick={() => setShow(!show)}
          rotate={show}
          icon={<ArrowDown small={true} />}
        />
        <Label size={"small"} style={{ marginLeft: "8px" }}>
          {t("Dynamic Load Management")}
        </Label>
      </TreeSubDropdown>

      {show && (
        <>
          {!loading ? (
            <div
              style={{
                padding: "20px",
                background: "white",
                margin: "0 20px 20px 20px",
              }}
            >
              <Box
                direction="row"
                width={100}
                padding="20px 0 0 0"
                justify="space-between"
              >
                <SelectField
                  options={["Disabled", "Base-Current", "Base-Energy"]}
                  label={t("Solar-based Charging")}
                  onChange={() => setChanged(true)}
                  selected={solarFormatted(data?.solarBasedChargingConfig.mode)}
                  innerRef={solarMode}
                  width={50}
                />
                <InputField
                  value={data?.solarBasedChargingConfig.basePower}
                  type="number"
                  onChange={() => setChanged(true)}
                  label={t("Base Current per EV (A): L1, L2, L3")}
                  innerRef={solarPower}
                  width={45}
                />
                {/* <InputField
                  value={data?.solarBasedChargingConfig.baseEnergy}
                  type="number"
                  onChange={() => setChanged(true)}
                  label={t("Base Energy (kWh)")}
                  innerRef={solarEnergy}
                  width={20}
                /> */}
              </Box>
              <Box
                direction="row"
                width={100}
                padding="20px 0 0 0"
                justify="space-between"
              >
                <SelectField
                  options={["Disabled", "Enabled"]}
                  label={t("Building-based Charging")}
                  onChange={() => setChanged(true)}
                  selected={data?.buildingBasedChargingConfig.mode}
                  innerRef={buildingMode}
                  width={50}
                />
                <InputField
                  type="number"
                  onChange={() => setChanged(true)}
                  label={t("Maximum Threshold (A)")}
                  value={data?.buildingBasedChargingConfig.maximumTreshold}
                  innerRef={buildingTreshold}
                  width={45}
                />
              </Box>
              {/* <Box
                direction="row"
                width={100}
                padding="20px 0 20px 0"
                justify="space-between"
              >
                <SelectField
                  options={["Disabled", "Enabled"]}
                  label={t("Peak-Demand Prevention")}
                  selected={data?.peakDemandPreventionConfig.mode}
                  onChange={() => setChanged(true)}
                  innerRef={peakMode}
                  width={50}
                />
                <InputField
                  type="number"
                  onChange={() => setChanged(true)}
                  label={t("Maximum Energy Demand per 15min Intervall")}
                  value={
                    data?.peakDemandPreventionConfig.maximumEnergyDemandPer15Min
                  }
                  innerRef={peakInterval}
                  width={45}
                />
              </Box> */}
              {changed && (
                <Box padding="20px 0 0 0" justify="space-between">
                  {changed && <Warning>{t("Unsaved changes.")}</Warning>}
                  <Button
                    margin="0 0 0 20px"
                    onClick={() => updateClusterConfig()}
                    text={t("Save Changes")}
                  />
                </Box>
              )}
            </div>
          ) : (
            <Loader />
          )}
        </>
      )}
    </>
  )
}
