/* eslint-disable react/display-name */
import FloadingLoader from "components/Icons/floading/Loader"
import Loading from "components/Icons/Loading.gif"
import React from "react"

import { StyledLoader } from "./Loader.styled"

const FeCLoader = () => (
  <StyledLoader>
    <img src={Loading} alt="" />
  </StyledLoader>
)

let Loader: React.FC

if (process.env.REACT_APP_COMPANY === "floading") {
  Loader = () => <FloadingLoader />
} else {
  Loader = () => <FeCLoader />
}

export default Loader
