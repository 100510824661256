import React, { useRef, useState } from "react"

import { StyledButtonIcon } from "./Button.styled"

interface Props {
  onClick: () => void
  icon: React.ReactNode
  text?: React.ReactNode
  rotate?: boolean
  reverse?: boolean
  disabled?: boolean
  hoverText?: string
  small?: boolean
}

const ButtonIcon = ({
  onClick,
  icon,
  text,
  rotate,
  reverse,
  disabled,
  hoverText,
  small,
}: Props) => {
  const [show, setShow] = useState(false)

  const tooltip = useRef<HTMLDivElement>(null)

  const hide = true
  return (
    <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      <StyledButtonIcon
        disabled={disabled}
        type="button"
        onClick={onClick}
        rotate={rotate ? 1 : 0}
        small={small}
      >
        {!reverse && icon} {text && text} {reverse && icon}
      </StyledButtonIcon>
      {/* {hoverText && show && !hide && (
        <div
          ref={tooltip}
          style={{
            border: "1px solid black",
            position: "absolute",
            background: "black",
            color: "white",
            padding: "2px 14px",
            marginLeft: tooltip.current?.clientWidth
              ? `${0 - Number(tooltip.current?.clientWidth)}px`
              : 0,
            borderRadius: "8px",
            zIndex: "99",
            whiteSpace: "nowrap",
          }}
        >
          <div
            style={{
              width: "0",
              height: "0",
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderBottom: "10px solid #000",
              position: "absolute",
              marginTop: "-8px",
              marginLeft: "70px",
            }}
          />
          {hoverText}
        </div>
      )} */}
    </div>
  )
}

export default ButtonIcon
