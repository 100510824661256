import { CardTable, StyledCardContainer } from "components/Card/Card.styled"
import { Box } from "components/Shared/Box.styled"
import Table from "components/Table/Table"
import { TableRows } from "components/types/types"
import { TableProvider } from "helpers/TableProvider"
import { ChargepointTable } from "helpers/TableStructures"
import _ from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  offline?: boolean
  cps: any[]
  loadingChargePoints: boolean
}

const separateConnectors = (cp: any) => {
  const connectors = cp.config.connectorNum
  return _.range(connectors).map((i) => ({
    ...cp,
    id: `${cp.id}-${i}`,
    connection:
      typeof cp.connection === "object" ? cp.connection[0] : cp.connection,
    cpState: [cp.cpState ? cp.cpState[i] : "init"],
    allocated: [cp.allocated ? cp.allocated[i] : { l1: 0, l2: 0, l3: 0 }],
    actual: [cp.actual ? cp.actual[i] : { l1: 0, l2: 0, l3: 0 }],
    reason: [cp.reason ? cp.reason[i] : ""],
    adaptiveChargingStatus: [
      cp.adaptiveChargingStatus ? cp.adaptiveChargingStatus[i] : "",
    ],
    advancedAdaptiveChargingStatus: [
      cp.advancedAdaptiveChargingStatus
        ? cp.advancedAdaptiveChargingStatus[i]
        : "",
    ],
    config: {
      ...cp.config,
      connectorId: i,
      priority: [cp.config.priority ? cp.config.priority[i] : 0],
      physicalLimit: [cp.config.physicalLimit ? cp.config.physicalLimit[i] : 0],
      descriptions: [cp.config.descriptions ? cp.config.descriptions[i] : ""],
    },
  }))
}

const Chargepoints = ({ offline, cps, loadingChargePoints }: Props) => {
  const [searchString, setSearchString] = useState("")
  const [search, setSearch] = useState("")
  const [pageSizeChargePoints, setPageSizeChargePoints] = useState(10)
  const [pageNumberChargePoints, setPageNumberChargePoints] = useState(1)
  const chargepointData = loadingChargePoints
    ? []
    : cps.flatMap((cp) => separateConnectors(cp))
  const { t } = useTranslation()

  return (
    <>
      <StyledCardContainer offline={offline}>
        <CardTable>
          <TableProvider
            searchString={setSearchString}
            search={setSearch}
            searchValue={searchString}
            priority={false}
            noAction={true}
          >
            <Box margin="0 0 40px 0">
              <Table
                layout={ChargepointTable}
                data={
                  chargepointData
                    .filter((cp) => cp.id.includes(searchString))
                    .slice(
                      (pageNumberChargePoints - 1) * pageSizeChargePoints,
                      pageNumberChargePoints * pageSizeChargePoints
                    ) as TableRows[]
                }
                loading={loadingChargePoints}
                toolbar={true}
                title={t("Chargepoints")}
                pageSize={pageSizeChargePoints}
                setPageSize={setPageSizeChargePoints}
                pageNumber={pageNumberChargePoints}
                setPageNumber={setPageNumberChargePoints}
                totalSize={chargepointData.length}
                totalPage={Math.ceil(
                  chargepointData.length / pageSizeChargePoints
                )}
              />
            </Box>
          </TableProvider>
        </CardTable>
      </StyledCardContainer>
    </>
  )
}

export default Chargepoints
